import {redisGetHandler} from "../utils/redis";

export default (axios, config) => ({
    async getSections(Redis = false, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, '/sections/getSections', reset);
    },
    async getSectionMeta(id) {
        const {data} = await axios.get(`${config.API_URL}/sections/getSectionMeta?id=${id}`);
        return data
    },
})