export const landings = [
    "farkop-audi.ru",
    "farkop-bmw.ru",
    "farkop-changan.ru",
    "farkop-chery.ru",
    "farkop-chevrolet.ru",
    "farkop-citroen.ru",
    "farkop-dodge.ru",
    "farkop-dongfeng.ru",
    "farkop-exeed.ru",
    "farkop-fiat.ru",
    "farkop-ford.ru",
    "farkop-gac.ru",
    "farkop-gaz.ru",
    "farkop-geely.ru",
    "farkop-haval.ru",
    "farkop-honda.ru",
    "farkop-hyundai.ru",
    "farkop-infiniti.ru",
    "farkop-iveco.ru",
    "farkop-jac.ru",
    "farkop-jeep.ru",
    "farkop-jetour.ru",
    "farkop-kia.ru",
    "farkop-lada.ru",
    "farkop-landrover.ru",
    "farkop-lexus.ru",
    "farkop-lixiang.ru",
    "farkop-mazda.ru",
    "farkop-mercedes.ru",
    "farkop-mitsubishi.ru",
    "farkop-nissan.ru",
    "farkop-opel.ru",
    "farkop-peugeot.ru",
    "farkop-porsche.ru",
    "farkop-renault.ru",
    "farkop-seat.ru",
    "farkop-skoda.ru",
    "farkop-ssangyong.ru",
    "farkop-subaru.ru",
    "farkop-suzuki.ru",
    "farkop-tank.ru",
    "farkop-toyota.ru",
    "farkop-uaz.ru",
    "farkop-volkswagen.ru",
    "farkop-volvo.ru",
];