export default (axios, config) => ({
    async vacancy(params) {
        const req = Object.entries(params).map(params => params.join(`=`)).join(`&`);
        const {data} = await axios.post(`${config.API_URL}/forms/vacancy?${req}`, {}, {withCredentials: true});
        return data;
    },
    async serviceOrder(params) {
        const req = Object.entries(params).map(params => params.join(`=`)).join(`&`);
        const {data} = await axios.post(`${config.API_URL}/forms/serviceOrder?${req}`, {}, {withCredentials: true});
        return data;
    },
    async serviceQuestion(params) {
        const req = Object.entries(params).map(params => params.join(`=`)).join(`&`);
        const {data} = await axios.post(`${config.API_URL}/forms/serviceQuestion?${req}`, {}, {withCredentials: true});
        return data;
    },
    async orderInstall(params) {
        const req = Object.entries(params).map(params => params.join(`=`)).join(`&`);
        const {data} = await axios.post(`${config.API_URL}/forms/orderInstall?${req}`, {}, {withCredentials: true});
        return data;
    },
    async findCheaper(params) {
        const req = Object.entries(params).map(params => params.join(`=`)).join(`&`);
        const {data} = await axios.post(`${config.API_URL}/forms/findCheaper?${req}`, {}, {withCredentials: true});
        return data;
    },
    async backCall(params) {
        const req = Object.entries(params).map(params => params.join(`=`)).join(`&`);
        const {data} = await axios.post(`${config.API_URL}/forms/backCall?${req}`, {}, {withCredentials: true});
        return data;
    },
    async feedBack(params) {
        const req = Object.entries(params).map(params => params.join(`=`)).join(`&`);
        const {data} = await axios.post(`${config.API_URL}/forms/feedBack?${req}`, {}, {withCredentials: true});
        return data;
    },
    async addRevew(params) {
        const req = Object.entries(params).map(params => params.join(`=`)).join(`&`);
        const {data} = await axios.post(`${config.API_URL}/productForms/addRevew?${req}`, {}, {withCredentials: true});
        return data;
    },
    async addQuestion(params) {
        const req = Object.entries(params).map(params => params.join(`=`)).join(`&`);
        const {data} = await axios.post(`${config.API_URL}/productForms/addQuestion?${req}`, {}, {withCredentials: true});
        return data;
    },
    async addComment(params) {
        const req = Object.entries(params).map(params => params.join(`=`)).join(`&`);
        const {data} = await axios.post(`${config.API_URL}/productForms/addComment?${req}`, {}, {withCredentials: true});
        return data;
    },
    async videoConsultation(params) {
        const req = Object.entries(params).map(params => params.join(`=`)).join(`&`);
        const {data} = await axios.post(`${config.API_URL}/forms/videoConsultation?${req}`, {}, {withCredentials: true});
        return data;
    },
    async message(params) {
        const req = Object.entries(params).map(params => params.join(`=`)).join(`&`);
        const {data} = await axios.post(`${config.API_URL}/forms/message?${req}`, {}, {withCredentials: true});
        return data;
    },
    async like(params) {
        const req = Object.entries(params).map(params => params.join(`=`)).join(`&`);
        const {data} = await axios.post(`${config.API_URL}/forms/like?${req}`, {}, {withCredentials: true});
        return data;
    },
    async dislike(params) {
        const req = Object.entries(params).map(params => params.join(`=`)).join(`&`);
        const {data} = await axios.post(`${config.API_URL}/forms/dislike?${req}`, {}, {withCredentials: true});
        return data;
    },
    async quiz(params) {
        const req = Object.entries(params).map(params => params.join(`=`)).join(`&`);
        const {data} = await axios.post(`${config.API_URL}/forms/quiz?${req}`, {}, {withCredentials: true});
        return data;
    },
    async subscribeProduct(params) {
        const req = Object.entries(params).map(params => params.join(`=`)).join(`&`);
        const {data} = await axios.post(`${config.API_URL}/forms/subscribeProduct?${req}`, {}, {withCredentials: true});
        return data;
    },
});
