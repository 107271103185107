import {toQueryString} from "~/utils";

export default (axios, config) => ({
    async getUser() {
        const {data} = await axios.get(`${config.API_URL}/user/getUser`, {withCredentials: true});
        return data;
    },
    async updateInfo({name, lastName}) {
        const {data} = await axios.post(`${config.API_URL}/user/updateInfo?name=${name}&lastName=${lastName}`, {}, {withCredentials: true});
        return data;
    },
    async updateAutos(autos) {
        const {data} = await axios.post(`${config.API_URL}/user/updateAutos?autos=${autos}`, {}, {withCredentials: true});
        return data;
    },
    async getPersonalAutos() {
        const {data} = await axios.get(`${config.API_URL}/user/getPersonalAutos`);
        return data;
    },
    async unsubscribe(params) {
        const {data} = await axios.get(`${config.API_URL}/user/unsubscribe?${toQueryString(params)}`);
        return data;
    },
    async logOut() {
        await axios.get(`${config.API_URL}/user/logOut`);
    }
});
