export async function redisGetHandler(redis, axios, apiPath, path, reset = false) {
    let url = apiPath + path;
    if (reset) {
        url += (path.indexOf('?') > -1 ? '&' : '?') + 'clear_cache=' + reset;
    }
    if (!redis) {
        const {data} = await axios.get(url);
        return data;
    }

    try {
        const [redisKey, ...keyArray] = path.split('/').filter(item => !!item)
        const key = keyArray.join('/');
        if (!reset) {
            const cachedData = await redis.hget('Api_' + redisKey, key);
            if (cachedData) {
                return JSON.parse(cachedData)
            }
        }   
        const {data} = await axios.get(url);
        if (data) {
            await redis.hset('Api_' + redisKey, key, JSON.stringify(data));
        } else {
            await redis.hdel('Api_' + redisKey, key);
        }
        return data;
    } catch(e) {
        console.log(e)
        const {data} = await axios.get(url);
        return data
    }

}

