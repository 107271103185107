import {redisGetHandler} from "../utils/redis";

export default (axios, config) => ({
    async getServices(Redis, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, '/services/getServices', reset);
    },
    async getService(Redis, code, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, `/services/getService?code=${code}`, reset);
    },
    async getServiceInstall(Redis, code, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, `/services/getServiceInstall?code=${code}`, reset);
    },
    async getServicesInstall(Redis, code, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, `/services/getServicesInstall?code=${code}`, reset);
    }
});
