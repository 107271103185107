export default (axios, config) => ({
  async getStatuses() {
    const {data} = await axios.get(`${config.API_URL}/orders/getStatuses`, {withCredentials: true});
    return data;
  },
  async getOrder(orderId) {
    const {data} = await axios.get(`${config.API_URL}/orders/getOrder?id=${orderId}`, {withCredentials: true});
    return data;
  },
  async getPayLink(orderId) {
    const {data} = await axios.get(`${config.API_URL}/orders/getPayLink?id=${orderId}`, {withCredentials: true});
    return data;
  },
  async getOrders() {
    const {data} = await axios.get(`${config.API_URL}/orders/getOrders`, {withCredentials: true});
    return data;
  },
  async updateOrderPhone(id, phone) {
    const {data} = await axios.post(`${config.API_URL}/orders/updateOrderPhone?id=${id}&phone=${phone}`);
    return data;
  },
});
