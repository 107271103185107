 import {redisGetHandler} from "../utils/redis";

export default (axios: any, config: any) => ({
  async getPoints(cityName: string, regionName = '', kladrId = '') {
    const {data} = await axios.post(`${config.API_URL}/edost/getPoints?city_name_new=${cityName}&region_name=${regionName}&kladr_id=${kladrId}`, {}, {withCredentials: true});
    return data;
  },
  async getCalc(params: any) {
    const req = Object.entries(params).map(params => params.join(`=`)).join(`&`);
    const {data} = await axios.get(`${config.API_URL}/edost/getCalcNew?${req}`, {}, {withCredentials: false});
    return data;
  },
  async getMinDays(Redis = false, cityName: string, kladr: string) {
    return await redisGetHandler(Redis, axios, config.API_URL, `/edost/getMinDays?cityName=${encodeURIComponent(cityName)}&kladr=${kladr}`);
  },
  async getMinPrice(
    Redis = false,
    params = {},
    reset = false
  ) {
    const req = Object.entries(params).map(params => params.join(`=`)).join(`&`);
    return await redisGetHandler(Redis, axios, config.API_URL, encodeURI(`/edost/getMinPrice?${req}`), reset);
  },
  async getPointsOld(cityName: string, regionName = '') {
    const {data} = await axios.get(`${config.API_URL}/edost/getPointsOld?city_name=${cityName}&region_name=${regionName}`, {}, {withCredentials: true});
    return data;
  },
});
