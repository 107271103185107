import {toQueryString} from "../utils";

export default (axios, config) => ({
  async getSdekPoints(zip) {
    const {data} = await axios.get(`${config.API_URL}/delivery/getSdekPoints?zip=`+zip);
    return data
  },
  async getProductsDelivery({productIds, shopIds}) {
    const url = `${config.API_URL}/delivery/getProductsDelivery`
    const {data} = await axios.get(url + '?' + toQueryString({productIds, shopIds}));
    return data
  },
  async getProductsDeliveryNew(productId) {
    const {data} = await axios.get(`${config.API_URL}/delivery/getProductsDeliveryNew?productId=${productId}`);
    return data
  },
  async getDeliveryDiscounts() {
    const {data} = await axios.get(`${config.API_URL}/delivery/getDeliveryDiscounts`);
    return data
  }
})