import {redisGetHandler} from "../utils/redis";

export default (axios, config) => ({
    async getMainSlider(Redis, domain, reset = false) {
        return await redisGetHandler(false, axios, config.API_URL, `/landings/getMainBanner?domain=${domain}`, true);
    },
    async getMainSections(Redis, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, '/landings/getMainSections', reset);
    },
    async getMainAdv(Redis, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, '/landings/getMainAdv', reset);
    },
    async getModels(Redis, domain, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, `/landings/getModels?domain=${domain}`, reset);
    },
    async getMainProduction(Redis, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, '/landings/getMainProduction', reset);
    },
    async getMainNews(Redis, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, '/landings/getMainNews', reset);
    },
    async getNews(Redis, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, '/landings/getNews', reset);
    },
    async getProduction(Redis, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, '/landings/getProduction', reset);
    },
    async getNewsDetail(Redis, code, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, `/landings/getNewsDetail?code=${code}`, reset);
    },
    async getProductionDetail(Redis, code, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, `/landings/getProductionDetail?code=${code}`, reset);
    },
    async getAboutContent(Redis, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, `/landings/getAboutContent`, reset);
    },
    async getMeta(Redis, domain, page, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, `/landings/getMeta?domain=${domain}&page=${page}`, reset);
    },
});
