import {redisGetHandler} from "../utils/redis";

export default (axios, config) => ({
    async getCatalogMeta(Redis, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, `/catalogSeo/getCatalogMeta`, reset);
    },
    async getCatalogMetaBaltex(Redis, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, `/catalogSeo/getCatalogMetaBaltex`, reset);
    },
    async getMarkNames(Redis, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, `/catalogSeo/getMarkNames`, reset);
    },
   async getFilterNames(Redis, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, `/catalogSeo/getFilterNames`, reset);
    },
    async getManagerComments(page) {
        const url = `${config.API_URL}/catalogSeo/getComment`
        const {data} = await axios.get(url + '?page=' + page);
        return data;
    },
});