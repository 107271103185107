import { DeliveryListResponse, PaymentListResponse } from "../types/api/cart";
import {toQueryString} from "../utils";
import {redisGetHandler} from "../utils/redis";

export default (axios: any, config: any) => ({
  async getDelivery() {
    const data = await axios.post(`${config.API_URL}/cart/getDelivery`);
    return data.data
  },
  async getBasketNew() {
    const {data} = await axios.post(`${config.API_URL}/cart_v2/getBasket`, {}, {withCredentials: true});
    return data
  },
  async getCart(formData = {}) {
    const url = `${config.API_URL}/cart/getCart`
    const data = await axios.post(url + '?' + toQueryString(formData), {}, {withCredentials: true});
    return data
  },
  async addToCart(id: string) {
    const {data} = await axios.post(`${config.API_URL}/cart_v2/addToCart?id=${id}`, {}, {withCredentials: true});
    return data
  },
  async remove(id: string) {
    const {data} = await axios.post(`${config.API_URL}/cart/removeItem?id=${id}`, {}, {withCredentials: true});
    return data
  },
  async changeQty({id, qty}: any) {
    const {data} = await axios.post(`${config.API_URL}/cart_v2/changeQty?id=${id}&qty=${qty}`, {}, {withCredentials: true});
    return data
  },
  async getDeliveryList(Redis: any, reset = false): Promise<DeliveryListResponse> {
    return await redisGetHandler(Redis, axios, config.API_URL, `/cart_v2/getDeliveryList`, reset);
  },
  async getPaymentList(Redis: any, reset = false): Promise<PaymentListResponse> {
    return await redisGetHandler(Redis, axios, config.API_URL, `/cart_v2/getPaymentList`, reset);
  },
  async initOrder(params: any) {
    const req = Object.entries(params).map(params => params.join(`=`)).join(`&`);
    const data = await axios.post(`${config.API_URL}/cart_v2/initOrderAuth?${req}`, {}, {withCredentials: true});
    return data.data
  },
});
