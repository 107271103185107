import axios from "axios";
import catalog from './catalog'
import product from './product'
import models from './models'
import forms from './forms'
import auth from './auth'
import location from './location'
import user from './user'
import content from './content'
import news from './news'
import cart from './cart'
import delivery from './delivery'
import services from './services'
import brands from './brands'
import orders from './orders'
import sections from './sections'
import photo from './photo'
import props from './props'
import sdek from "./sdek";
import edost from "./edost";
import sitemap from "./sitemap";
import basket from "./basket";
import server from "~/api/server";
import catalogSeo from "~/api/catalogSeo";
import dadata from "~/api/dadata";
import baltex from "~/api/baltex";
import lendings from "~/api/lendings";

let API_URL = '';
let API_SERVER_URL = '';
if (typeof window === "undefined") {
    API_URL = process.env.API_URL as string;
    API_SERVER_URL = process.env.API_SERVER_URL as string;
} else if (window.location.host.includes('farkop.ru')) {
    API_URL = 'https://farkop.ru/bxApi'
    API_SERVER_URL = '/api'
} else {
    API_URL = '/bxApi'
    API_SERVER_URL = '/api'
}

const config = {
    API_URL,
    API_SERVER_URL
}

export const Api = {
    catalog: catalog(axios, config),
    catalogSeo: catalogSeo(axios, config),
    product: product(axios, config),
    models: models(axios, config),
    auth: auth(axios, config),
    forms: forms(axios, config),
    location: location(axios, config),
    user: user(axios, config),
    content: content(axios, config),
    news: news(axios, config),
    cart: cart(axios, config),
    basket: basket(axios, config),
    delivery: delivery(axios, config),
    services: services(axios, config),
    brands: brands(axios, config),
    orders: orders(axios, config),
    sections: sections(axios, config),
    photo: photo(axios, config),
    props: props(axios, config),
    sdek: sdek(axios, config),
    edost: edost(axios, config),
    sitemap: sitemap(axios, config),
    server: server(axios, config),
    dadata: dadata(axios, config),
    baltex: baltex(axios, config),
    lendings: lendings(axios, config),
}