import {redisGetHandler} from "../utils/redis";

export default (axios, config) => ({
    async getMainSlider(Redis, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, '/content/getMainSlider', reset);
  },
  async getMainPopular(Redis, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, '/content/getMainPopular', reset);
  },
  async getMainNumbers(Redis, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, '/content/getMainNumbers', reset);
  },
  async getMainAdv(Redis, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, '/content/getMainAdv', reset);
  },
  async getLastNews(Redis, size, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, `/content/getLastNews?size=${size}`, reset);
  },
    async getTeam(Redis, reset = false) {
      return await redisGetHandler(Redis, axios, config.API_URL, '/content/getTeam', reset);
  },
  async getVacancies(Redis, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, '/content/getVacancies', reset);
  },
    async getVacanciesSection(Redis, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, '/content/getVacanciesSections', reset);
    },
  async getVideos(Redis, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, '/content/getVideos', reset);
  },
    async getQuestions(Redis, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, '/content/getQuestions', reset);
  },
    async getReviews(Redis, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, '/content/getReviews', reset);
  },
    async getCertificates(Redis, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, '/content/getCertificates', reset);
  },
    async getCertificateByBrand(Redis, id, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, `/content/getCertificateByBrand?id=${id}`, reset);
    },
    async getCatalogBanners(Redis, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, '/content/getCatalogBanners', reset);
    },
    async getProductReviews(Redis, productId, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, `/content/getProductReviewsNew?productId=${productId}`, reset);
  },
  async getProductQuestions(Redis, productId, reset = false) {
      return await redisGetHandler(Redis, axios, config.API_URL, `/content/getProductQuestionsNew?productId=${productId}`, reset);
  },
  async getScripts(Redis, reset = false) {
      return await redisGetHandler(Redis, axios, config.API_URL, '/content/getScripts', reset);
  },
  async getMeta(Redis, domain, page, reset = false) {
      return await redisGetHandler(Redis, axios, config.API_URL, `/content/getMeta?domain=${domain}&page=${page}`, reset);
  },
  async getMainNews(Redis, reset = false) {
    return await redisGetHandler(Redis, axios, config.API_URL, `/content/getMainNews`, reset);
  },
  async getMainProductsReviews(Redis, reset = false, domain) {
    return await redisGetHandler(Redis, axios, config.API_URL, `/content/getMainProductsReviews?domain=${domain}`, reset);
  },
  async getMainPortfolio(Redis, reset = false, domain) {
    return await redisGetHandler(Redis, axios, config.API_URL, `/content/getMainPortfolio?domain=${domain}`, reset);
  },
    async getServicePortfolio(Redis, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, `/content/getServicePortfolio`, reset);
    },
  async getMainReviews(Redis, reset = false) {
    return await redisGetHandler(Redis, axios, config.API_URL, `/content/getMainReviews`, reset);
  },
  async fetchShopReviews(Redis, reset = false) {
    return await redisGetHandler(Redis, axios, config.API_URL, `/content/getMainReviews`, reset);
  },
    async getAboutContent(Redis, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, `/content/getAboutContent`, reset);
    },
    async getSertContent(Redis, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, `/content/getSertContent`, reset);
    },
    async getSettings(Redis, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, `/content/getSettings`, reset);
    },
    async getPromoVideos(Redis, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, `/content/getPromoVideo`, reset);
    },
});
